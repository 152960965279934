.appointmentFormBox {
  padding: 20px 20px;
}

.appointmentFormInputItem {
  margin-top: 10px;
  border: 1px lightgray solid;
  min-height: 40px !important;
  height: 40px !important;
}

.appointmentFormInputItem input {
  font-size: 14px !important;
}

.am-list-item.am-input-item {
  padding-left: 10px !important;
}

.certificateTypeSelect {
  margin-top: 10px;
}

.ant-select-lg {
  font-size: 14px !important;
}

.confirmBtn {
  margin-top: 20px;
}

.hintBox {
  width: 100%;
  height: 120px;
  background-color: white;
  border: 1px lightgray solid;
  margin-top: 30px;
  color: #295f6d;
}

.hintTitle {
  margin-top: 20px;
  margin-left: 15px;
  font-size: 16px;
}

.hintLabel {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 20px;
  font-size: 12px;
}

a:hover {
  color: white !important;
}

.hintContent {
  color: gray;
  font-size: 12px;
  background-color: transparent;
  margin: 100px 40px;
  text-align: center;
}