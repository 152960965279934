.riskEvaluationDashboard {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.riskEvaluationSegmentedControl {
  margin-top: 10px;
  width: 120px;
}

.riskTip {
  margin-top: 10px;
}

.riskEvaluationQuestionBox {
  margin-top: 10px;
}

.ant-radio-group {
  display: flex !important;
  flex-direction: column;
}

.ant-radio-wrapper {
  white-space: inherit !important;
}

.personalRiskBtnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.scoreEvaluationTable {
  width: 100%;
}

.scoreEvaluationTable table {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-item-control {
  line-height: 20px !important;
}

.ant-form-explain {
  margin-left: 22px;
}

.investorCategory {
  font-weight: bold;
}