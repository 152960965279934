.personalFundBox {
  background-color: #F0F0F0;
}

/* Personal Fund Row */
.personalFundRowItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
}

/* Personal Fund Row Title Box */
.personalFundProductRowTitleBox {
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
  align-items: center;
}

.personalFundTitleRedBox {
  background-color: #1970b6;
  line-height: 40px;
  height: 30px;
  width: 6px;
}

.personalFundTitle {
  font-size: 16px;
  margin-left: 15px;
  line-height: 20px;
}

.personalFundValueBox {
  display: flex;
  flex-direction: column;
  border: 2px lightgray solid;
  border-radius: 5px;
  margin: 0px 15px;
  background-color: white;
}

/* 累计收益 */
.personalFundTotalReturnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px lightgray solid;
}

.personalFundTotalReturnValue {
  color: red;
  font-size: 18px;
  margin-right: 10px;
}

.personalFundTotalReturnLabel {
  color: gray;
  font-size: 12px;
  margin-right: 6px;
}

.personalFundTotalReturnTipLabel {
  color: gray;
  font-size: 10px;
}

.personalFundValueRow2Box {
  display: flex;
  flex-direction: row;
  /* padding-top: 10px; */
  /* background-color: green; */
}

/* 单位净值 */
.personalFundPriceBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-left: 30px;
  border-right: 1px lightgray solid;
}

.personalFundPriceValue {
  font-size: 18px;
  margin-top: 10px;
}

.personalFundPriceLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}


/* 月收益 */
.personalFundMonthReturnBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-right: 30px;
}

.personalFundMonthReturnValue {
  margin-top: 10px;
  font-size: 18px;
}

.personalFundMonthReturnLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}

.personalFundViewDetailBtnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
}

.am-list-body {
  background-color: transparent !important;
  margin-top: 10px;
}

.personalFundBox .am-list-item {
  min-height: 30px !important;
  background-color: #1970b6 !important;
  padding-left: 20px !important;
}

.am-list-item .am-list-line .am-list-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  white-space: normal !important;
}

.list-view-section-body {
  margin-top: 5px;
}

.sectionHeader {
  font-size: 15px;
  color: white !important;
}

.am-popover-inner-wrapper {
  margin: 20px;
}