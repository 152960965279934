/*  */
.fundProductBox {
  background-color: #F0F0F0;
}

/* Fund Product Row */
.fundProductRowItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-color: transparent;
}

/* Fund Product Row Title Box */
.fundProductRowTitleBox {
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
  align-items: center;
}

.fundProductTitleRedBox {
  background-color: #1970b6;
  line-height: 40px;
  height: 30px;
  width: 6px;
}

.fundProductTitleTitle {
  font-size: 16px;
  margin-left: 15px;
}

.fundProductValueBox {
  display: flex;
  flex-direction: column;
  height: 200px;
  border: 2px lightgray solid;
  border-radius: 5px;
  margin: 0px 15px;
  background-color: white;
}

/* 累计收益 */
.totalReturnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px lightgray solid;
}

.totalReturnValue {
  color: red;
  font-size: 18px;
  margin-right: 30px;
}

.totalReturnLabel {
  color: gray;
  font-size: 12px;
}

.fundProductValueRow2Box {
  display: flex;
  flex-direction: row;
  /* padding-top: 10px; */
  /* background-color: green; */
}

/* 单位净值 */
.priceBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-left: 30px;
  border-right: 1px lightgray solid;
}

.priceValue {
  font-size: 18px;
  margin-top: 10px;
}

.priceLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}


/* 月收益 */
.monthReturnBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-right: 30px;
}

.monthReturnValue {
  margin-top: 10px;
  font-size: 18px;
}

.monthReturnLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
}

.viewDetailBtnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
}

.am-modal-button-group-v .am-modal-button {
  color: black !important;
}