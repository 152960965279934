#root {
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;
}

.grid-wrapper {
  height: 100%;
}

.contentBox {
  background-color: #F0F0F0;
  height: 100%;
}

body {
  background-color: #F0F0F0 !important;
}