.notice-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notice-content-top {
  flex: 0.5;
}

.notice-content {
  flex: 1;
}

.ant-result {
  padding: 0 0 !important;
}