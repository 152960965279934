/* Personal Fund Detail Box */
.personalFundDetailBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
}

/* Personal Fund Detail Row Title Box */
.personalFundDetailRowTitleBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.personalFundDetailTitleBox {
  background-color: #1970b6;
  height: 30px;
  width: 6px;
}

.personalFundDetailTitle {
  font-size: 16px;
  margin-left: 15px;
}

.personalFundDetailValueBox {
  display: flex;
  flex-direction: column;
  border: 2px lightgray solid;
  border-radius: 5px;
  margin: 0px 15px;
  background-color: white;
}

/* 累计收益 */
.personalFundDetailTotalReturnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px lightgray solid;
}

.personalFundDetailTotalReturnValue {
  color: red;
  font-size: 18px;
  margin-right: 10px;
}

.personalFundDetailTotalReturnLabel {
  color: gray;
  font-size: 12px;
  margin-right: 6px;
}

.personalFundDetailTotalReturnTipLabel {
  color: gray;
  font-size: 10px;
}

.personalFundDetailValueRow2Box {
  display: flex;
  flex-direction: row;
  margin: 5px 20px;
}

/* 单位净值 */
.personalFundDetailPriceBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px lightgray solid;
}

.personalFundDetailPriceValue {
  font-size: 18px;
  margin-top: 5px;
}

.personalFundDetailPriceLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}


/* 份额 */
.personalFundDetailShareBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personalFundDetailShareValue {
  margin-top: 10px;
  font-size: 18px;
}

.personalFundDetailShareLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}

.personalFundDetailSegmentedControlBox {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

/* 基金概述 */
.personalFundDetailBasicInfo {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: calc(100vh - 220px);
  background-color: white;
  padding: 15px 15px;
}

.personalBasicInfoTitle {
  font-size: 18px;
  font-weight: 500;
  color: #055064;
}

.personalBasicInfoListBox {
  margin-top: 15px;
}

.personalBasicInfoRowItemBox {
  min-height: 30px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.personalBasicInfoRowItemBox:nth-child(even) {
  background-color: #ecf8f8;
}

.personalBasicInfoField {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  min-width: 100px;
}

.personalBasicInfoLabel {
  font-size: 14px;
}

/* 产品净值 */
.personalFundValueGridBox {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: calc(100vh - 220px);
  background-color: white;
  overflow: auto;
}

/* 交易历史 */
.tradeRecordBox {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: calc(100vh - 220px);
  background-color: white;
  overflow: auto;
}

table {
  width: 550px;
  border: 1px solid lightgray;
}

thead td {
  width: 110px;
  height: 30px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

td {
  width: 110px;
  height: 24px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}