.authQueryBox {
  padding: 30px 20px;
}

.countDownBox {
  display: flex;
  flex-direction: row;
}

.phoneNoInputItem {
  background-color: lightgray !important;
  width: 100%;
  min-height: 40px !important;
  height: 40px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.phoneNoInputItem input {
  background-color: lightgray !important;
  font-size: 14px !important;
}

.authCodeInputItem {
  margin-top: 10px;
  min-height: 40px !important;
  height: 40px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.authCodeInputItem input {
  font-size: 14px !important;
}

.confirmBtn {
  margin-top: 20px;
}

.hint {
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
  margin: 5px 40px;
}

/* .am-list-item .am-input-control input {
  font-size: 14px !important;
} */