/* Fund Detail Box */
.fundDetailBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
}

/* Fund Detail Row Title Box */
.fundDetailRowTitleBox {
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
  align-items: center;
}

.fundDetailTitleRedBox {
  background-color: #1970b6;
  line-height: 40px;
  height: 30px;
  width: 6px;
}

.fundDetailTitle {
  font-size: 16px;
  margin-left: 15px;
}

.fundDetailValueBox {
  display: flex;
  flex-direction: column;
  border: 2px lightgray solid;
  border-radius: 5px;
  margin: 0px 15px;
  background-color: white;
}

/* 累计收益 */
.detailTotalReturnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  /* background-color: brown; */
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px lightgray solid;
}

.detailTotalReturnValue {
  color: red;
  font-size: 18px;
  margin-right: 30px;
}

.detailTotalReturnLabel {
  color: gray;
  font-size: 12px;
}

.fundDetailValueRow2Box {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  /* padding-top: 10px; */
  /* background-color: green; */
}

/* 单位净值 */
.detailPriceBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-left: 30px;
  border-right: 1px lightgray solid;
}

.detailPriceValue {
  font-size: 18px;
  margin-top: 10px;
}

.detailPriceLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}


/* 月收益 */
.detailMonthReturnBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-right: 30px;
}

.detailMonthReturnValue {
  margin-top: 10px;
  font-size: 18px;
}

.detailMonthReturnLabel {
  font-size: 10px;
  margin: 5px 10px;
  text-align: center;
  color: gray;
  line-height: 15px;
}

.segmentedControlBox {
  margin-left: 15px;
  margin-right: 15px;
}

/* 基金概述 */
.fundDetailBasicInfo {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: calc(100vh - 270px);
  background-color: white;
  padding: 15px 15px;
}

.basicInfoTitle {
  font-size: 18px;
  font-weight: 500;
  color: #055064;
}

.basicInfoListBox {
  margin-top: 5px;
}

.basicInfoRowItemBox {
  min-height: 30px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.basicInfoRowItemBox:nth-child(even) {
  background-color: #ecf8f8;
}

.basicInfoField {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  min-width: 100px;
}

.basicInfoLabel {
  font-size: 14px;
}

/* 产品净值 */
.fundValueBox {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: calc(100vh - 270px);
  background-color: white;
  overflow: auto;
  /* padding: 15px 15px; */
}

table {
  width: 550px;
  border: 1px solid lightgray;
}

thead td {
  width: 110px;
  height: 30px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

td {
  width: 110px;
  height: 24px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}